<template>
  <TogglePanel :title="$tc('group', 1)">
    <div style="padding:0 10px">
      <div class="form-group form-group-sm">
        <label>{{ $t("expression") }}</label>
        <JSONPathPicker
          :append="true"
          :curlyBracked="true"
          :entry="expressionTemplate"
          entryType="item"
          v-model="expression"
        />
      </div>
      <template>
        <ControlStyleProperties v-model="aggregationStyle" />
        <TogglePanel
          :title="$tc('icon', 2)"
          v-if="aggregation.enabled"
          style="margin-top:10px;"
        >
          <div class="form-group form-group-sm" style="margin-bottom:0;">
            <div class="input-group">
              <div class="form-control text-center text-bold">
                {{ $t("closed") }}
              </div>
              <IconLibrary
                v-model="iconCloseClass"
                :buttonAttrs="{ title: iconCloseClass }"
                :panelOpen.sync="iconPicker.close"
                class="input-group-addon no-padding icons"
              >
                <template #button>
                  <div class="btn" @click.prevent="iconPicker.close = true">
                    <i :class="iconCloseClass"></i>
                  </div>
                </template>
              </IconLibrary>
              <div class="input-group-addon no-padding">
                <ColorPicker
                  v-model="iconCloseColor"
                  :pickerStyle="{ left: '-200px' }"
                  icon="background"
                />
              </div>
            </div>
          </div>
          <div class="form-group form-group-sm">
            <div class="input-group">
              <div class="form-control  text-center text-bold">
                {{ $t("openned") }}
              </div>
              <IconLibrary
                v-model="iconOpenClass"
                :buttonAttrs="{ title: iconOpenClass }"
                :panelOpen.sync="iconPicker.open"
                class="input-group-addon no-padding icons"
              >
                <template #button>
                  <div class="btn" @click.prevent="iconPicker.open = true">
                    <i :class="iconOpenClass"></i>
                  </div>
                </template>
              </IconLibrary>
              <div class="input-group-addon no-padding">
                <ColorPicker
                  v-model="iconOpenColor"
                  :pickerStyle="{ left: '-200px' }"
                  icon="background"
                />
              </div>
            </div>
          </div>
        </TogglePanel>
      </template>
      <TogglePanel
        title="actions"
        style="margin-top:10px"
        v-if="!aggregation.enabled"
      >
        <div style="padding: 0 10px">
          <ControlActionSelector
            eventName="click"
            :exclude="excludedActions"
            :control="aggregation"
            @addAction="addAction($event)"
          />
          <ControlEventActionForm
            style="clear:both;margin: 0 -10px;"
            v-for="(action, ixAction) in actions"
            eventName="click"
            :key="ixAction"
            :action="action"
            :index="ixAction"
            @delAction="onDelAction($event)"
            @change="onActionChange($event)"
          />
        </div>
      </TogglePanel>
    </div>
  </TogglePanel>
</template>

<script>
import { isEqual } from "lodash";
import Actions from "@/assets/dashboard/actions.json";
import Events from "@/assets/dashboard/events.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import IconLibrary from "@/components/editor/icon-library.vue";
import ColorPicker from "@/components/editor/color-picker.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import { stats } from "@/components/control-sidebar/property-editors/device-list-table-form.vue";

import ControlActionSelector from "@/components/editor/control-action-selector.vue";
import ControlEventActionForm from "@/components/editor/control-event-action-form.vue";
export default {
  name: "ColumnGroupForm",
  components: {
    TogglePanel,
    ControlStyleProperties,
    IconLibrary,
    ColorPicker,
    JSONPathPicker,
    ControlActionSelector,
    ControlEventActionForm
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data: () => ({
    aggregation: null,
    iconPicker: {
      open: false,
      close: false
    }
  }),
  computed: {
    aggregationStyle: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation, "style", vlr);
        }
      },
      get() {
        return this?.aggregation?.style;
      }
    },
    iconOpenClass: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation.icons.open, "class", vlr);
        }
      },
      get() {
        return this?.aggregation?.icons?.open?.class;
      }
    },
    iconCloseClass: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation.icons.close, "class", vlr);
        }
      },
      get() {
        return this?.aggregation?.icons?.close?.class;
      }
    },
    iconOpenColor: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation.icons.open, "color", vlr);
        }
      },
      get() {
        return this?.aggregation?.icons?.open?.color;
      }
    },
    iconCloseColor: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation.icons.close, "color", vlr);
        }
      },
      get() {
        return this?.aggregation?.icons?.close?.color;
      }
    },
    expression: {
      set(vlr) {
        if (this?.aggregation) {
          this.$set(this.aggregation, "expression", vlr);
        }
      },
      get() {
        return this.aggregation.expression;
      }
    },
    expressionTemplate() {
      return {
        ...stats(),
        name: "",
        icons: {
          connected: true,
          disconnected: true,
          alarm_on: true,
          alarm_off: true,
          enable: true,
          connector: true,
          device: true,
          data: true,
          alarm: true,
          connectorConnection: true,
          deviceConnection: true
        }
      };
    },
    excludedActions() {
      let actions = Actions.filter(
        ({ type }) => !/^(page|screen)/.test(type)
      ).map(({ type }) => type);
      (this?.aggregation?.on?.click?.actions || []).forEach(({ type }) => {
        actions.push(type);
      });
      return actions;
    },
    actions() {
      return this?.aggregation?.on?.click?.actions || [];
    }
  },
  watch: {
    value: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.aggregation)) return;
          this.aggregation = n;
        }
      },
      immediate: true
    },
    aggregation: {
      handler(n) {
        if (n) {
          if (isEqual(n, this.value)) return;
          this.$emit("input", n);
        }
      }
    }
  },
  methods: {
    addAction(action) {
      let aggregation = JSON.parse(JSON.stringify(this.aggregation));
      aggregation.on = aggregation.on || {};
      aggregation.on.click = aggregation.on.click || Events["click"];
      aggregation.on.click.actions.push(action);
      this.$set(this, "aggregation", aggregation);
    },
    onActionChange(ixAction) {},
    onDelAction(ix) {
      let aggregation = JSON.parse(JSON.stringify(this.aggregation));
      aggregation.on = aggregation.on || {};
      aggregation.on.click = aggregation.on.click || Events["click"];
      if (ix < aggregation.on.click.actions.length) {
        aggregation.on.click.actions.splice(ix, 1);
        this.$set(this, "aggregation", aggregation);
      }
    }
  },
  mounted() {
    if (this.$refs.iconOpen) {
      this.$refs.iconOpen.$on("update:panelOpen", (value) => {
        this.iconPicker.open = value;
      });
    }
    if (this.$refs.iconClose) {
      this.$refs.iconClose.$on("update:panelOpen", (value) => {
        this.iconPicker.close = value;
      });
    }
  }
};
</script>

<style scoped>
.input-group-addon.icons > .btn {
  padding: 0;
  min-width: 32px;
}
</style>
