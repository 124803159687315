import { render, staticRenderFns } from "./column-group-form.vue?vue&type=template&id=1d515d00&scoped=true&"
import script from "./column-group-form.vue?vue&type=script&lang=js&"
export * from "./column-group-form.vue?vue&type=script&lang=js&"
import style0 from "./column-group-form.vue?vue&type=style&index=0&id=1d515d00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d515d00",
  null
  
)

export default component.exports